::-webkit-scrollbar {
  width: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 0.3px;
}

:root {
  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgb(46, 54, 69); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgb(46, 54, 69);
}
